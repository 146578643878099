* {
  padding: 0;
  margin: 0;
}

button {
  cursor: pointer;
  transition: 0.3s;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

.node {
  background: #e3e3e3 !important;
  border: 1px solid #8f8f8f !important;
  width: 300px;
}

.input-control {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  /* flex-direction: column; */
}

.input-control label {
  text-transform: capitalize;
  font-size: 14px;
}

.input-control select {
  width: 160px;
  border: none;
  padding: 5px;
  border-radius: 5px;
  outline: none;
}

/* .output-title {
  display: none !important;
} */

.control {
  width: 100%;
}

.control input {
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  /* margin-top: 5px; */
}

.output-socket-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: 2px;
  padding: 15px 0;
  margin-top: -47px;
}

.socket.input.socket-circle,
.socket.output.socket-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.socket.input {
  margin-left: -5px !important;
}

.socket.output {
  margin-right: -5px !important;
}

.connection .main-path {
  fill: none;
  stroke-width: 2px !important;
  stroke: #e3e3e3 !important;
}

.context-menu {
  width: 130px !important;
  background-color: rgb(103, 103, 103);
  height: auto;
  padding: 0 !important;
  border-radius: 4px !important;
}

.context-menu .item {
  background-color: rgb(103, 103, 103) !important;
  border-bottom: none !important;
  height: 25px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 
.context-menu .item::after {
  font-family: "Poppins";
  content: "Add Node";
  position: absolute;
  left: 0px;
  right: 0;
  top: 0;
  background-color: rgb(236, 236, 236) !important;
  border-bottom: none !important;
  padding: 10px !important;
  font-size: 14px;
  padding-left: 35px !important;
  color: #242424;
}

.context-menu .item::before {
  content: "+";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-22%);
  border-bottom: none !important;
  font-size: 18px;
  z-index: 10;
  width: 10px;
  margin-left: 5px;
  color: #242424;
} */

.context-menu .item:hover {
  background: rgb(147, 147, 147) !important;
}

.context-menu .item:nth-child(1) {
  border-radius: 5px;
}

.context-menu .item:nth-child(2) {
  display: none;
}

.form-container {
  display: flex;
  flex: 1;
  height: 100vh;
}

.form-container .left {
  width: 50%;
  height: 100%;
  background-color: #fde802;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container .right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.form-container .form {
  width: 300px !important;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-container input {
  width: 100%;
  padding: 10px 0;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

.form-container button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.form-container button:hover {
  background-color: #0056b3;
}

.editor-screen-header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
}

.editor-screen-header button {
  border: 1px solid #e3e3e3;
  background: none;
  outline: none;
  min-width: 80px;
  height: 30px;
}

.get-json-btn {
  transition: 0.2s;
  cursor: pointer;
}

.get-json-btn:hover {
  background: #e3e3e3;
}

.popup-modal {
  min-width: 330px;
  padding: 10px 0;
  background: white !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.113);
}

.popup-modal table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: white;
}

.popup-modal td,
.popup-modal th {
  text-align: left;
  padding: 8px 20px;
  font-size: 12px;
  min-width: 50px;
}

.popup-modal tr:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}

.node .title {
  color: black !important;
  display: flex;
  justify-content: space-between;
}
.node .title button {
  background-color: white;
  border: 1px solid #e3e3e3;
  outline: none;
  padding: 4px;
}

legend {
  color: black;
  text-align: center;
  padding: 0 5px;
}

fieldset {
  background: none;
  outline: none;
  border: 0;
  border-top: 1px solid black;
  margin: 20px 0 10px;
}

.get-journey {
  margin-right: 50px;
}
.get-journey input {
  padding: 6px;
  margin-right: 10px;
  outline: none;
  border: none;
  border: 1px solid #e3e3e3;
  transition: 0.3s;
  width: 170px;
}

.get-journey input:focus {
  border-color: #b9b9b9 !important;
}

.get-journey button {
  padding: 0 10px !important;
}

.get-journey button:hover {
  background: #e3e3e3;
}

.modal-container {
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.05);
  transform: translate(-50%, -50%);
  background: white;
}

.modal-container-header {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
}

.modal-container-header button {
  color: white;
  background-color: red;
  padding: 2px 5px;
  border: none;
  border-radius: 2px;
}

.modal-container-body {
  padding: 5px 20px;
}

.journey-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.journey-card .journey-name {
  border: 1px solid #e3e3e3;
  padding: 10px 15px;
  text-align: center;
  text-transform: capitalize;
}

.step2-container {
  width: 300px;
  padding: 20px 0;
  overflow: hidden;
}

.step2-container > div {
  display: flex;
  align-items: center;
}

#journey {
  width: 100%;
  height: 30px;
  border: 1px solid #e3e3e3;
  outline: none;
}

.step2-container button {
  border: 1px solid #e3e3e3;
  background: none;
  outline: none;
  min-width: 80px;
  height: 30px;
  transition: 0.3s;
  float: right;
}

.step2-container button:hover {
  background: #e3e3e3;
}

.logout.get-json-btn {
  border: 1px solid #e3e3e3;
  background: none;
  outline: none;
  min-width: 80px;
  height: 30px;
  margin-right: 35px;
  margin-top: 15px;
}
